import React from "react";
import {Helmet} from "react-helmet"
import { Link } from "gatsby";
// markup
const IndexPage = () => {
  return (
    <main>
           <Helmet>
        <title>Leonardo Feynman</title>
      </Helmet>
      <h1>Leonardo Feynman</h1>
      <p>I create educational history content.</p>
      <p>You're probably here from TikTok and looking for one of these:</p>
      <p>Check out this cool <Link to="/net-worth-simulator">net worth simulator</Link>.</p>
      <p>Check out this cooler <Link to="/roman-emperor-simulator">roman emperor simulator</Link>.</p>
      <p>Check out this other <Link to="/roman-emperor-simulator-claudius">roman emperor simulator (claudius edition)</Link>.</p>
      <p>Cause the american revolution with this <Link to="/cause-american-revolution-simulator">british simulator</Link>.</p>
      <p>Be an American President with the <Link to="/president-selection">american president simulator</Link>.</p>
  
      <p><a href="https://www.youtube.com/channel/UC9wN8OUBPs4qIwBi1XOxF1g">Subscribe to me </a> on YouTube to boost my self esteem </p>
    </main>
  )
}

export default IndexPage
